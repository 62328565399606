<template>
  <div id="app"  @mousemove="moveBackground">
    <div class="background">
      <!-- Background Animation -->
      <div class="background-animation"
        :style="{ transform: `translate(${backgroundX}px, ${backgroundY}px)` }"
      >
        <DotLottieVue
          style="height: 90vh; width: 90vw"
          :autoplay="true"
          :loop="true"
          src="animation.lottie"
        />
      </div>

      <!-- Foreground Content -->
      <div class="foreground">
        <h1 class="audiowide-regular">BrainBox Hosting</h1>
        <div><a class="audiowide-regular" href="https://brainbox.gr">https://brainbox.gr</a></div>
      </div>
  </div>
  </div>
</template>

<script>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'

export default {
  name: 'App',
  components: {
    DotLottieVue,
  },
  data() {
    return {
      backgroundX: 0,
      backgroundY: 0,
    };
  },
  methods: {
    moveBackground(event) {
      // Get the mouse position relative to the viewport
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      // Calculate new position based on mouse movement
      // Adjust these values to change the movement sensitivity
      this.backgroundX = (mouseX - window.innerWidth / 2) * 0.05; // 0.05 is the sensitivity factor
      this.backgroundY = (mouseY - window.innerHeight / 2) * 0.05;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.audiowide-regular {
  font-family: "Audiowide", sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* Reset and global styles */
* {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

/* Background Animation Styles */
.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.1s; /* Smooth transition for movement */
}

.background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #2e2e2e, #4b4b4b, #6e6e6e); /* Radial gray gradient */
}

#svg-background {
  width: 100%;
  height: 100%;
}

/* Foreground Content Styles */
.foreground {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 10;
  margin-top: 100px;
}

h1 {
  font-size: 3em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
a,a:visited{
  color: #fff;
}
a:hover{
  color: #6e6e6e;
}
</style>
